/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// next
import dynamic from 'next/dynamic';

// images
// import appleLogo from "assets/img/logo-apple.svg";
// import facebookLogo from "assets/img/logo-facebook.svg";
// import nasaLogo from "assets/img/logo-nasa.svg";
// import vodafoneLogo from "assets/img/logo-vodafone.svg";
// import digitalOceanLogo from "assets/img/logo-digitalocean.svg";

// MUI
const Container = dynamic(() => import('@mui/material/Container'), { loading: () => null });
const Divider = dynamic(() => import('@mui/material/Divider'), { loading: () => null });
const Grid = dynamic(() => import('@mui/material/Grid'), { loading: () => null });

// Pro Components
const MDTypography = dynamic(() => import('components/MDTypography'), { loading: () => null });

// Parts
const DefaultReviewCard = dynamic(() => import('examples/DefaultReviewCard'), { loading: () => null });

function Reviews() {
  return (
    <Container>
      <Grid
        container
        item
        xs={12}
        lg={6}
        justifyContent="center"
        sx={{ mx: 'auto', textAlign: 'center' }}
      >
        <MDTypography variant="h2" sx={{ display: 'block', width: '100%' }}>Trusted by</MDTypography>
        <br />
        {' '}
        <MDTypography variant="h2" color="info" textGradient mb={2} sx={{ display: 'block', width: '100%' }}>
          teams and clients
        </MDTypography>
        <MDTypography variant="body1" color="text" mb={2}>
          Teams and startups trust CodeFox to deliver.
        </MDTypography>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 8 }}>
        <Grid item xs={12} md={6} lg={4}>
          <DefaultReviewCard
            color="transparent"
            name="Lisa P."
            date="2023"
            review="Looks great!"
            rating={5}
            image={null}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DefaultReviewCard
            color="info"
            name="Lenny M."
            date="2023"
            review="Product configurator is awesome!"
            rating={5}
            image={null}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DefaultReviewCard
            color="transparent"
            name="Phil F."
            date="2022"
            review="You rock at code and people skills."
            rating={5}
            image={null}
          />
        </Grid>
        {/* <Grid item xs={12} md={6} lg={3}>
          <DefaultReviewCard
            color="error"
            name="Garrett"
            date="2016"
            review="Turned site sketches into reality."
            rating={5}
          />
        </Grid> */}
      </Grid>
      <Divider sx={{ my: 6 }} />
      {/* End Testimonials */}
      {/* <Grid container spacing={3} justifyContent="center">
              <Grid item xs={6} md={4} lg={2}>
                <Image src={appleLogo} alt="Apple" width="100%" opacity={0.6} />
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <Image src={facebookLogo} alt="Facebook" width="100%" opacity={0.6} />
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <Image src={nasaLogo} alt="Nasa" width="100%" opacity={0.6} />
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <Image src={vodafoneLogo} alt="Vodafone" width="100%" opacity={0.6} />
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <Image
                  src={digitalOceanLogo}
                  alt="DigitalOcean"
                  width="100%"
                  opacity={0.6}
                />
              </Grid>
            </Grid> */}
    </Container>
  );
}

export default Reviews;
